<template>
  <div id="app" class="h-100 py-2 container" style="max-width: 480px; position: relative;">
  
    <router-view v-if="logged" />
    <div class="mt-4" v-else>
      <h1>Magico Pracownik</h1>
      Musisz się zalogować<br>
      <button class="btn btn-outline-primary" @click="authenticate('oauth2')">ZALOGUJ</button>


    </div>
    <router-link v-if="logged" style="position: absolute; top:10px; right:10px" to="/menu">
      <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="40px" height="40px"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"/></svg>
    </router-link>
  </div>
</template>
<script>

export default {
  name: 'AuthenticateApp',
  props: {
    msg: String
  },
  data: function () {
    return {
      logged: false,
    }
  },
  methods: {
    authenticate: function (provider) {
      let vm=this;
      this.$auth.authenticate(provider, { grant_type: 'authorization_code' }).then(function () {
        // Execute application logic after successful social authentication
       // alert('Zalogowano pomyślnie');
       vm.logged=true;
      })
    },
  },
  mounted() {
    if (this.$auth.isAuthenticated()) {
      this.logged = true;
    } else {
      this.logged = false;
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
