import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import VueAuthenticate from 'vue-authenticate'
import axios from 'axios';
import "bootstrap/scss/bootstrap.scss";
//import "./registerServiceWorker.js";
import './registerServiceWorker'
console.log(process.env.VUE_APP_API_URL);
Vue.use(VueAxios, axios.create({
    baseURL: process.env.VUE_APP_API_URL,
}))
Vue.use(VueAuthenticate, {
    baseUrl: process.env.VUE_APP_OAUTH2_BASE_URL, // Your API domain
    providers: {
        oauth2: {
            url: process.env.VUE_APP_OAUTH2_TOKEN_URL,
            authorizationEndpoint: process.env.VUE_APP_OAUTH2_AUTHORIZE_URL,
            clientId: process.env.VUE_APP_OAUTH2_CLIENT_ID,
            defaultUrlParams: ['response_type', 'client_id', 'grant_type', 'state', 'redirect_uri'],
            grantType: 'authorize',
            state: '123',
            redirect_uri: process.env.VUE_APP_OAUTH2_CALLBACK_URL,
            responseParams: {
                code: 'code',
            }
        },
    },
    bindRequestInterceptor: function () {
        this.$http.interceptors.request.use(config => {
            config.headers['Authorization'] = ['Bearer', this.getToken()].join(' ');
            return config;
        });
        var $auth = this;
        this.$http.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            console.log('REFRESH ACCESS TOKEN INCEPTOR', 'access_token')

            // console.log('interceptor', error)
            const originalRequest = error.config;
            // console.log('CODE', error.response.status, originalRequest, originalRequest._retry, originalRequest.url)
            if (error.response.status === 401 && !originalRequest._retry) {
                // console.log('ACCESS TKENT DUPA REFRESHU')
                originalRequest._retry = true;
                //try {
                // console.log('this', await $auth.refreshToken('oauth2', {}))
                // const access_token = VueAuthenticate.refreshAccessToken();
                console.log('REFRESH ACCESS TOKEN INCEPTOR', 'access_token')
                return await $auth.refreshToken('oauth2', {}).then(function (response) {
                    // console.log(response.data, response.data.access_token);
                    originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                    // alert(response.data.access_token);
                    return $auth.$http(error.config);
                }, function () {
                    // return $auth.$http(originalRequest);
                    localStorage.clear();
                    location.replace("/");
                    return Promise.reject(error);
                })
                // } catch (error) {
                // console.error(error);
                // expected output: ReferenceError: nonExistentFunction is not defined
                // Note - error messages will vary depending on browser
                // }
            }
            return Promise.reject(error);
        });
    }
})

Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')