<template>
    <div class="about mt-4">
        <div class="mb-4">
            <img src="https://www.magico.pl/files/MaterialyDoPobrania/logo_magico_png_whitebackgroung_min.png"
                alt="Agencja Kreatywna Magico" width="200">
        </div>
        <div v-show="!success">
            <h2>Wniosek urlopowy</h2>
            <div v-if="error" class="alert alert-danger">{{error_msg?error_msg:'Wystąpił błąd'}}</div>
            <div class="form-group text-start mb-2">
                <label>Początek urlopu</label>
                <DatePicker v-model="start" type="date" :disabled-date="getMinStartDate" value-type="YYYY-MM-DD"
                    format="YYYY-MM-DD" class="d-block w-100" input-class="form-control"></DatePicker>
            </div>
            <div class="form-group text-start mb-2">
                <label>Koniec urlopu</label>
                <DatePicker v-model="stop" type="date" :disabled-date="getMinEndDate" value-type="YYYY-MM-DD"
                    format="YYYY-MM-DD" class="d-block w-100" input-class="form-control"></DatePicker>
            </div>
            <div class="form-group text-start mb-2">
                <label>Powód</label>
                <select v-model="reason" class="form-select">
                    <option v-for="item in reasons" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
            <div class="form-group text-start mb-2">
                <label>Dodatkowe informacje</label>
                <textarea v-model="info" class="form-control">

            </textarea>
            </div>
            <button :disabled="!start || !stop || !reason || loading" @click="send" class="btn btn-success mt-4 w-100 fs-5">Wyślij
                wniosek</button>
        </div>
        <div v-show="success" class="text-center">
            <div class="alert alert-success">Twój wniosek został wysłany!</div>
            <button class="btn btn-outline-secondary" @click="success = false">
                Ok
            </button>
        </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pl';

export default {
    components: {
        DatePicker
    },
    data: function () {
        return {
            start: '',
            stop: '',
            reason: '',
            info: '',
            reasons: [],
            employee: {},
            success: false,
            error: false,
            loading:false,
        }
    },
    methods: {
        load: function () {
            let vm = this;
            this.$http('api/v2/slownik/wykaznieobecnosci').then(response => {
                vm.reasons = response.data;
            })
            this.$http('api/v2/employees/me').then(response => {
                vm.employee = response.data;
            })
        },
        getMinStartDate: function (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today
        },
        getMinEndDate: function (date) {
            if (this.start) {
                const today = new Date(this.start);
                today.setHours(0, 0, 0, 0);
                today.setDate(today.getDate() - 1)
                return date <= today
            }
            return true;
        },
        send: function () {
            this.loading=true;
            this.success = false;
            this.error = false;
            this.error_msg='';
            var data = {
                start: this.start,
                end: this.stop,
                employee_id: this.employee.id,
                powod: this.info,
                powod_id: this.reason,
                status: 0
            };
            this.$http.post('api/v2/kalendarzurlopow/urlop', data).then(
                () => {
                    this.success = true;
                    this.start = '';
                    this.stop = '';
                    this.reason = '';
                    this.info = '';
                    this.loading=false;
                },
                (error) => {
                    this.loading=false;
                    if(error.response.data.message){
                        this.error_msg = error.response.data.message;
                    }
                    this.error = true;
                });
        },
    },
    mounted() {
        this.load();
    }
}
</script>