<template>
    <div class="about mt-4">
        <div class="mb-4">
            <img src="https://www.magico.pl/files/MaterialyDoPobrania/logo_magico_png_whitebackgroung_min.png"
                alt="Agencja Kreatywna Magico" width="200">
        </div>
        <router-link to="/" class="btn btn-block  w-100 btn-outline-secondary mb-2">Grafik</router-link>
        <router-link v-show="holidayOn" to="holiday" class="btn btn-block  w-100 btn-outline-secondary mb-2">Dodaj
            wniosek urlopowy</router-link>
        <div style="color:white; position: fixed; left:0px;bottom:0px" @click="block()">---</div>
    </div>
</template>
<script>
export default {
    computed: {
        holidayOn: function () {
            return !localStorage.getItem('blockHoliday');
        }
    },
    methods: {
        block: function () {
            window.location.reload()
            if (this.holidayOn) {
                localStorage.setItem('blockHoliday', 1);
            } else {
                localStorage.removeItem('blockHoliday');
            }
        }
    }
}
</script>